import React from 'react'
import './Description.css';
import Description1 from 'D:/gbmart/src/assets/image/description-pic.png';

const Description = () => {
  return (
    <div className="row d-flex align-items-center " id="description">
        <hr className='my-4'/>
      <div className="col-lg-auto col-xs col-sm-auto col-auto">
        <div>
          <img src={Description1} className="img-fluid" />
        </div>
      </div>
      <div className="col-lg col-xs col-sm-auto col-auto pe-2 ">
        <div>
          <h3 className="mt-3">A Graphic Designer with 6 Yrs  Exp | B.A</h3>
          <p className="mt-3 mb-0">
            Visual Communication, I can help you create a Branded  Presentation
            or Post Style/System to make your designs look more consistent and
            professional  This will save your time, money and energy in the long
            run.
          </p>
        </div>
      </div>
      <hr className='mt-4'/>
    </div>
  );
}

export default Description;
